/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
//var $ = require('../plugins/jquery/jquery.js');
require('../plugins/bootstrap/css/bootstrap.css');
require('../plugins/node-waves/waves.css');
require('../plugins/jquery-countto/jquery.countTo.js');
require('../plugins/animate-css/animate.css');
require('../plugins/bootstrap-material-datetimepicker/css/bootstrap-material-datetimepicker.css');
require('../plugins/bootstrap-select/css/bootstrap-select.css');
require('../css/style.css');
require('../css/themes/theme-cyan.css');
require('../plugins/chartjs/Chart.bundle.js');
require('../plugins/flot-charts/jquery.flot.js');
require('../plugins/flot-charts/jquery.flot.resize.js');
require('../plugins/flot-charts/jquery.flot.pie.js');
require('../plugins/flot-charts/jquery.flot.categories.js');
require('../plugins/flot-charts/jquery.flot.time.js');
require('../plugins/jquery-sparkline/jquery.sparkline.js');
//require('../plugins/jquery-datatable/jquery.dataTables.js');
//require('../plugins/jquery-datatable/skin/bootstrap/js/dataTables.bootstrap.js');
require('../js/admin.js');
require('../js/pages/index.js');
require('../js/demo.js');

// any CSS you require will output into a single css file (app.css in this case)
require('../css/app.scss');
//const Waves = require('node-waves');

const imagesContext = require.context('../images', true, /\.(png|jpg|jpeg|gif|ico|svg|webp)$/);
imagesContext.keys().forEach(imagesContext);

//const jsContext = require.context('../js', true, /\.(js)$/);
//jsContext.keys().forEach(jsContext);

//const cssContext = require.context('../css', true, /\.(css|scss)$/);
//cssContext.keys().forEach(cssContext);

//console.log('Hello Webpack Encore! Edit me in assets/js/app.js');
//window.$ = window.jQuery = $;